import { Label } from '@radix-ui/react-label'
import { useFetcher } from '@remix-run/react'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
	Dialog,
	DialogClose,
	DialogContent,
	DialogFooter,
	DialogHeader,
	DialogTrigger,
} from '#app/components/ui/dialog'
import { RadioGroup, RadioGroupItem } from '#app/components/ui/radio-group'
import { InputField } from './common/forms/input'
import PlentyBody from './typography/plenty-body'
import PlentyHeading from './typography/plenty-heading'
import { Button } from './ui/button'
import { Textarea } from './ui/textarea'

const ContactModal = ({
	isOpen,
	onClose,
}: {
	isOpen: boolean
	onClose: () => void
}) => {
	const [currentSelection, setCurrentSelection] = useState('')
	const { t } = useTranslation('common')
	const contactSupportFetcher = useFetcher()
	const options: { option: string }[] = [
		{ option: t('yes_label') },
		{ option: t('no_label') },
	]
	const [formData, setFormData] = useState({
		email: '',
		firstName: '',
		lastName: '',
		orderNumber: '',
		message: '',
		currentSelection: '',
	})

	const resetForm = () => {
		setFormData({
			email: '',
			firstName: '',
			lastName: '',
			orderNumber: '',
			message: '',
			currentSelection: '',
		})
	}

	const handleChange = (e: any) => {
		const { name, value } = e.target
		setFormData(prevFormData => ({
			...prevFormData,
			[name]: value,
		}))
	}

	const handleSend = async () => {
		contactSupportFetcher.submit(formData, {
			action: '/resources/createSupportEmailEvent',
			method: 'POST',
		})

		if (contactSupportFetcher.state !== 'submitting') {
			resetForm()
			onClose()
		}
	}

	return (
		<Dialog open={isOpen} onOpenChange={onClose}>
			<DialogTrigger asChild>
				<Button
					variant={'primary'}
					size={'primary'}
					className="flex w-full max-w-[800px] items-center gap-2 md:w-auto md:max-w-[400px]"
				>
					{t('contact_us')}
				</Button>
			</DialogTrigger>
			<DialogContent
				className=" overflow-y-scroll rounded-2xl p-8 "
				key="contact-content"
			>
				<DialogHeader className="flex justify-start gap-[10px] text-left">
					<PlentyHeading as="h6">
						{t('contact_us')}
					</PlentyHeading>
					<PlentyBody size="md">{t('get_in_touch')}</PlentyBody>
				</DialogHeader>
				<div className="flex flex-col space-y-6">
					<div className="flex flex-row justify-between space-x-6 sm:justify-between">
						<InputField
							error={undefined}
							name="firstName"
							value={formData.firstName}
							onChange={handleChange}
							className="space-y-[0px] md:space-y-[0px]"
							placeholder={t('checkout:first_name')}
						></InputField>
						<InputField
							error={undefined}
							name="lastName"
							value={formData.lastName}
							onChange={handleChange}
							className="space-y-[0px] md:space-y-[0px]"
							placeholder={t('checkout:last_name')}
						></InputField>
					</div>
					<InputField
						error={undefined}
						name="email"
						value={formData.email}
						onChange={handleChange}
						className="space-y-[0px] md:space-y-[0px]"
						placeholder={t('returns:email_input_placeholder')}
					></InputField>
					<PlentyBody size="md">{t('related_to_existing_order')}</PlentyBody>
					<RadioGroup className="space-y-2">
						{options.map(({ option }) => (
							<div className="flex items-center space-x-2" key={option}>
								<RadioGroupItem
									className="border-0 bg-white text-black-90 ring-2 ring-beige-50 aria-checked:ring-mint-green-80p"
									value={option}
									id={option}
									onClick={() => setCurrentSelection(option)}
								/>
								<Label htmlFor={option}>{option}</Label>
							</div>
						))}
					</RadioGroup>
					<div
						className={currentSelection === options[0].option ? '' : 'hidden'}
					>
						<InputField
							error={undefined}
							name="orderNumber"
							value={formData.orderNumber}
							onChange={handleChange}
							className="space-y-[0px] md:space-y-[0px]"
							placeholder={t('returns:order_number_input_placeholder')}
						></InputField>
					</div>
					<Textarea
						rows={5}
						name="message"
						value={formData.message}
						onChange={handleChange}
						placeholder={t('comment_placeholder')}
						className="px-6 py-4 text-[16px]"
					></Textarea>
				</div>
				<DialogFooter
					key="footer"
					className="align-start w-full items-start justify-start text-start"
				>
					<DialogClose
						asChild
						className="flex justify-center md:justify-start"
						key="change"
					>
						<Button
							variant="primary"
							size="default"
							onClick={handleSend}
							className="px-8 py-4 text-lg"
						>
							Send
						</Button>
					</DialogClose>
				</DialogFooter>
			</DialogContent>
		</Dialog>
	)
}

export default ContactModal
